<template>
  <base-material-card
    color="primary"
    icon="mdi-table"
    inline
    class="px-5 py-3"
  >
    <template v-slot:after-heading>
      <div class="d-flex">
        <div class="display-1 font-weight mr-1">
          {{ selectedTable ? `TABELA :: ${selectedTable}` : '' }}
        </div>

        <v-progress-circular
          v-if="loadingCountRows"
          size="20"
          indeterminate
          color="rgba(0, 0, 0, 0.87)"
        />

        <div
          v-else-if="selectedTable"
          class="display-1 font-weight"
        >
          :: {{ countRows | parseNumberToIntegerBR }} LINHAS
        </div>
      </div>
    </template>

    <v-row
      class="mx-auto my-8"
      align="center"
      no-gutters
    >
      <v-col
        cols="12"
        v-if="!selectedTable"
      >
        <v-alert
          dense
          outlined
          type="info"
          color="blue"
          class="mb-0 py-1 px-1"
        >
          Selecione uma tabela ao lado para poder realizar as consultas
        </v-alert>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <v-textarea
          class="mx-4 ta-padding-c"
          v-model="querySql"
          :disabled="loadingExecQuery"
          rows="10"
          no-resize
          background-color="#EEEEEE"
          placeholder="Escreva aqui sua consulta SQL"
          hint="Não insira ';' (ponto e vírgula) no final da query"
        />
      </v-col>
    </v-row>

    <div
      v-if="selectedTable"
      class="d-flex justify-end"
    >
      <v-btn
        color="success"
        min-width="100"
        @click="dialogSaveData = true"
      >
        Extrair
      </v-btn>
      <v-btn
        color="primary"
        min-width="100"
        @click="getQueryData"
        :loading="loadingExecQuery"
      >
        Executar
      </v-btn>
    </div>
    <dialog-save-data
      :table="selectedTable"
      :params="parametros"
      :dialog="dialogSaveData"
      @update:dialog="dialogSaveData = $event"
    />
  </base-material-card>
</template>

<script>
import ExtracoesGeoPerdasService from '@/services/ExtracoesGeoPerdasService';
import DialogSaveData from './DialogSaveData.vue';

export default {
  components: { DialogSaveData },

  props: {
    selectedTable: {
      type: String,
      default: () => null
    },
    databaseId: {
      type: Number,
      default: () => null
    },
    tables: {
      type: Array,
      default: () => []
    },
    active: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      headers: [],
      queryData: [],
      errorSql: null,
      pathTable: null,
      querySql: null,
      countRows: null,
      loadingSave: false,
      loadingExecQuery: false,
      loadingCountRows: false,
      dialogSaveData: false
    };
  },

  computed: {
    parametros() {
      return {
        databaseId: this.databaseId,
        query: this.querySql,
        tabela: this.pathTable
      };
    }
  },

  methods: {
    emitData() {
      this.$emit('update-values', {
        headers: this.headers,
        queryData: this.queryData,
        loading: this.loadingExecQuery,
        error: this.errorSql,
        recharge: true
      });
    },
    getQueryData() {
      this.loadingExecQuery = true;
      this.errorSql = null;
      this.queryData = [];
      this.headers = [];

      return ExtracoesGeoPerdasService.getQueryData(
        this.databaseId,
        this.querySql
      )
        .then(({ data }) => {
          this.queryData = data;

          this.headers =
            data.length === 0
              ? []
              : Object.keys(data[0]).map((key) => ({
                  text: key,
                  value: key
                }));
        })
        .catch((err) => {
          this.errorSql = err.data.error;
          this.$toast.error(
            'Erro ao buscar ao buscar os dados da tabela.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingExecQuery = false));
    },
    getCountRows(table) {
      this.loadingCountRows = true;

      return ExtracoesGeoPerdasService.getCountRows(this.databaseId, table)
        .then(({ data }) => {
          this.countRows = data[0].linhas;
        })
        .catch((err) => {
          this.$toast.error(
            'Erro ao buscar ao buscar a quantidade de linhas da tabela.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingCountRows = false));
    }
  },

  watch: {
    selectedTable(newTable) {
      if (!newTable) return;

      const selected = this.active.length ? this.active[0] : null;
      const item = this.tables[selected];

      if (!item) return;

      this.pathTable = `${item.path}.${item.tabela}`;
      this.querySql = `SELECT * FROM ${item.path}.[${item.tabela}]`;

      this.getQueryData();
      this.getCountRows(`${item.path}.[${item.tabela}]`);
    },
    headers() {
      this.emitData();
    },
    queryData() {
      this.emitData();
    },
    loadingExecQuery() {
      this.emitData();
    },
    errorSql() {
      this.emitData();
    },
    dialogSaveData() {
      this.emitData();
    }
  }
};
</script>
