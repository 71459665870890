<template>
  <v-row>
    <v-col cols="auto">
      <v-dialog
        v-model="isOpen"
        max-width="600"
      >
        <v-card>
          <v-card-title class="headline">
            {{ `Extrair tabela :: ${table}` }}
          </v-card-title>
          <v-card-text>
            <v-row class="mx-auto">
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:255"
                >
                  <v-text-field
                    placeholder="Informe o título da extração"
                    class="my-0 mx-4 mt-8"
                    v-model="titulo"
                    id="titulo-extracao"
                    label="Título"
                    outlined
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              color="primary"
              :disabled="disabled"
              :loading="loading"
              @click="save"
            >
              Salvar
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="closeDialog"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import ExtracoesGeoPerdasService from '@/services/ExtracoesGeoPerdasService';

export default {
  props: {
    table: {
      type: String,
      default: () => null
    },
    params: {
      type: Object,
      default: () => {}
    },
    dialog: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      titulo: null,
      loading: false
    };
  },

  computed: {
    isOpen: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      }
    },
    disabled() {
      return this.titulo ? false : true;
    },
    companyId() {
      return this.$store.getters.getCompanyId;
    }
  },
  methods: {
    save() {
      const saveData = {
        titulo: this.titulo,
        tabela: this.params.tabela,
        query: this.params.query,
        company_id: this.companyId,
        servidor_id: this.params.databaseId
      };

      return ExtracoesGeoPerdasService.save(saveData)
        .then(() => {
          this.$toast.success('Extração GeoPerdas salva com sucesso.', '', {
            position: 'topRight'
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar a Extração GeoPerdas.', '', {
            position: 'topRight'
          });
        })
        .finally(() => {
          this.loading = false;
          this.closeDialog();
        });
    },
    closeDialog() {
      this.isOpen = false;
    }
  },
  watch: {
    table() {
      this.titulo = this.table;
    }
  }
};
</script>
